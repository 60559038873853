const dataURIToBlob = dataURI => {
    const splitDataURI = dataURI.split(',');
    const byteString =
        splitDataURI[0].indexOf('base64') >= 0
            ? atob(splitDataURI[1])
            : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++)
        ia[i] = byteString.charCodeAt(i);

    return new Blob([ia], { type: mimeString });
};

export const imageToBase64 = (pathname, callback, type = 0) => {
    if (!pathname) return null;
    let url = process.env.REACT_APP_API_URL_IMG + pathname;
    if (type) {
        url = pathname;
    }
    return new Promise(function (resolve) {
        let xhr = new XMLHttpRequest();

        xhr.onload = function () {
            let reader = new FileReader();
            reader.onloadend = function () {
                callback(reader.result);
            };
            reader.readAsDataURL(xhr.response);
        };
        xhr.open('GET', url);

        xhr.responseType = 'blob';
        xhr.send();
    });
};

export function removeCSSClass(ele, cls) {
    const reg = new RegExp('(\\s|^)' + cls + '(\\s|$)');
    ele.className = ele.className.replace(reg, ' ');
}

export function addCSSClass(ele, cls) {
    ele.classList.add(cls);
}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export const urlImage = pathname => pathname !== "" ? process.env.REACT_APP_API_URL_IMG + pathname : "/media/nodata.png";

export const img64ToDataForm = image64 => {
    const file = dataURIToBlob(image64);
    const formData = new FormData();
    formData.append('upload', file, 'image.jpg');
    formData.append('path', 'temp/');
    return formData;
};

export const convertToSeconds = hms => {
    if (!hms) return 0;
    const arrayTime = hms.split(':');
    return Number(arrayTime[0] * 60) + Number(arrayTime[1]);
};
export const convertSecondsToTime = hms => {
    if (!hms) return '00:00';
    let cloneHms = `${hms}`;

    let minutes = Math.floor(cloneHms / 60);
    let seconds = cloneHms % 60;

    minutes = String(minutes).padStart(2, '0');
    seconds = String(seconds).padStart(2, '0');
    return minutes + ':' + seconds;
};
